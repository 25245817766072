.avater {
  max-width: 250px;
}
.BaseButton_BaseButton__2TRpd {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  text-decoration: none;
  color: #FFF;
  width: 50px;
  height: 50px;
  line-height: 30px;
  padding: 5px;
  font-size: 25px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  transition: .2s;
  border: solid 5px #FFF;
}

.TwitterLinkButton_TwitterLinkButton__1-suQ {
    background: #1da1f3;
}


.FacebookLinkButton_FacebookLinkButton__38c8B {
    background: #3b75d4;
}

.GithubLinkButton_GithubLinkButton__1uON3 {
    background: black;
}

:local .avatar {
    border-radius: 50%;
}
:local .developers > :not(:first-child) {
    margin-left: 20px;
}

